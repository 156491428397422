/**
 * @see https://mui.com/x/react-date-pickers/getting-started/#typescript
 */
import { createTheme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import {
  Palette as MuiPallete,
  PaletteOptions as MuiPaletteOptions,
} from '@mui/material/styles/createPalette';

type ThemeCustom = {
  inputBackgroundColor: string;
  inputBorderRadius: string;
  drawerWidthOpen: string;
  drawerWidthClose: string;
  muted: string;
  lightBlue: string;
  border: string;
  borderRadius: string;
  borderColorLight: string;
  boxShadowCard: string;
  boxShadowModal: string;
  boxShadowFeatured: string;
  boxShadowToolbar: string;
  headerHeight: string;
  flexCenter: {
    display: 'flex';
    alignItems: 'center';
    justifyContent: 'center';
  };
};

declare module '@mui/material/styles' {
  interface Theme {
    custom: ThemeCustom;
  }
  interface ThemeOptions {
    custom?: ThemeCustom;
  }
}

declare module '@mui/material/styles/createPalette' {
  interface Palette extends MuiPallete {
    shade: { main: string };
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line
  interface PaletteOptions extends MuiPaletteOptions {
    shade?: { main: string };
  }
}

const inputBackgroundColor = '#f7f7fc';
const inputBorderRadius = '10px';

const typography: TypographyOptions = {
  fontFamily: [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

// Create a theme instance.
const theme = createTheme({
  spacing: 8,
  typography,
  palette: {
    shade: { main: '#78858F' },
    background: { default: '#F7F7FC' },
    primary: { main: '#263577' },
    secondary: { main: '#E39720' },
    error: { main: '#F52419' },
    success: { main: '#00A344' },
    warning: { main: '#F4B740' },
  },
  components: {
    MuiContainer: {
      defaultProps: { maxWidth: 'xl' },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadiusSm: '8px',
          borderRadius: '8px',
          boxShadow: '0 2px 6px rgba(38, 53, 119, 0.1)',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: '0',
          minWidth: 'auto',
          fontWeight: 400,
        },
        sizeSmall: {
          padding: '8px 12px',
          ...typography.body2,
          lineHeight: '20px',
          borderRadius: 4,
        },
        sizeMedium: {
          ...typography.subtitle2,
          padding: '12px 16px',
          borderRadius: 6,
        },
        sizeLarge: {
          ...typography.subtitle1,
          padding: '14px 18px',
          borderRadius: 8,
        },
        containedPrimary: {
          ':hover': {
            backgroundColor: '#1B2654',
          },
        },
        containedSecondary: {
          color: '#fff',
          ':hover': {
            backgroundColor: '#BD7D18',
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          letterSpacing: '0',
          minWidth: 'auto',
          fontWeight: 400,
        },
      },
    },
    MuiFilledInput: {
      defaultProps: { disableUnderline: true },
      styleOverrides: {
        root: {
          borderRadius: inputBorderRadius,
          backgroundColor: inputBackgroundColor,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...typography.body1,
          color: '#6E7191',
          overflow: 'visible',
        },
        shrink: {
          ...typography.caption,
          fontSize: 14,
          transform: 'translate(12px, 12px) scale(0.75)',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '10px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'filled',
        InputProps: { disableUnderline: true },
      },
      styleOverrides: {
        root: {
          borderRadius: inputBorderRadius,
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        borderRadius: inputBorderRadius,
      },
    },
    MuiAlert: {
      styleOverrides: { root: { borderRadius: '10px' } },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          minHeight: 30,
          padding: '0 0.5rem',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          //minWidth: 0,
          minWidth: '34px',
          //justifyContent: 'left',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          ...typography.subtitle2,
        },
      },
    },
    MuiTableCell: {
      defaultProps: { size: 'small' },
    },
  },
  custom: {
    inputBackgroundColor,
    inputBorderRadius,
    drawerWidthOpen: '200px',
    drawerWidthClose: '72px',
    muted: '#78858F',
    lightBlue: '#F6F7FB',
    border: '1px solid rgba(38, 53, 119, 0.1)',
    borderRadius: '8px',
    borderColorLight: '#EFF0F6',
    boxShadowCard: '0 2px 6px rgba(38, 53, 119, 0.1)',
    boxShadowModal: '0 2px 8px rgba(0, 0, 0, 0.08)',
    boxShadowFeatured: '0 2px 12px rgba(38, 53, 119, 0.1)',
    boxShadowToolbar: '0 1px 8px rgba(0, 0, 0, 0.06)',
    headerHeight: '72px',
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
});

export default theme;
